<script lang="ts">
	import { formatAmount, type AmountLike } from '$lib/utils/currency'

	export let loading = false
	export let rounded = false
	export let amount: AmountLike
	export let originalAmount: AmountLike | undefined = undefined
	export let options: Intl.NumberFormatOptions = undefined

	let className = ''
	export { className as class }

	$: formattedAmount = loading
		? '123.00'
		: formatAmount(amount?.amount, amount?.currency, rounded, options)

	$: formattedOriginalAmount = originalAmount
		? formatAmount(originalAmount?.amount, originalAmount?.currency, rounded, options)
		: null

	$: hasDiscount = originalAmount && originalAmount.amount > amount.amount
</script>

<span data-testid="amount" class={`${className} ${loading ? 'blur-sm' : ''}`}>
	{#if hasDiscount}
		<span class="line-through text-brand-gray-4 mr-2">{formattedOriginalAmount}</span>
	{/if}
	<span class={hasDiscount ? 'text-black font-semibold' : ''}>{formattedAmount}</span>
</span>
