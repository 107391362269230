import { browser } from '$app/environment'

/* eslint-disable @typescript-eslint/ban-ts-comment */
export interface AmountLike {
	amount: string | number
	currency: string
}

export function toAmount(amountInCents: number, currency = 'USD'): AmountLike {
	if (isZeroDecimalCurrency(currency)) {
		return {
			amount: amountInCents ?? 0,
			currency,
		}
	}

	return {
		amount: amountInCents ? amountInCents / 100 : 0,
		currency,
	}
}

const getNavigatorLanguage = () => {
	if (!browser) return 'en-US'
	return navigator.languages && navigator.languages.length
		? navigator.languages[0]
		: // @ts-ignore
		  navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en-US'
}

export function formatAmount(
	amount: number | string,
	currency = 'USD',
	rounded = false,
	options?: Intl.NumberFormatOptions,
) {
	if (currency == '' || currency == null) currency = 'USD'

	return new Intl.NumberFormat(getNavigatorLanguage(), {
		style: 'currency',
		currency,
		minimumFractionDigits: rounded ? 0 : undefined,
		maximumFractionDigits: rounded ? 0 : undefined,
		// unitDisplay: 'narrow',
		// currencyDisplay: units ? 'narrowSymbol' : 'symbol',
		// notation: 'compact',
		...options,
	}).format(rounded ? Math.ceil(Number(amount ?? 0)) : Number(amount ?? 0))
}

export function isZeroDecimalCurrency(currency: string) {
	switch (currency) {
		case 'BIF': // Burundi Franc
		case 'CLP': // Chilean Peso
		case 'DJF': // Djiboutian Franc
		case 'GNF': // Guinean Franc
		case 'JPY': // Japanese Yen
		case 'KMF': // Comorian Franc
		case 'KRW': // South Korean Won
		case 'MGA': // Malagasy Ariary
		case 'PYG': // Paraguayan Guarani
		case 'RWF': // Rwandan Franc
		case 'UGX': // Ugandan Shilling
		case 'VND': // Vietnamese Dong
		case 'VUV': // Vanuatu Vatu
		case 'XAF': // Central African CFA Franc
		case 'XOF': // West African CFA Franc
		case 'XPF': // CFP Franc
			return true
		default:
			return false
	}
}

export const compactCurrencies = [
	'JPY',
	'IDR',
	'ARS',
	'THB',
	'PHP',
	'TRY',
	'ZAR',
	'COP',
	'EGP',
	'INR',
	'KRW',
	'RUB',
	'TWD',
	'VND',
	'BRL',
	'LBP',
]

export const supportedCurrencies = [
	{ id: 'AED', name: 'United Arab Emirates Dirham' },
	{ id: 'ARS', name: 'Argentine Peso' },
	{ id: 'AUD', name: 'Australian Dollar' },
	{ id: 'BRL', name: 'Brazilian Real' },
	{ id: 'CAD', name: 'Canadian Dollar' },
	{ id: 'CHF', name: 'Swiss Franc' },
	{ id: 'CNY', name: 'Chinese Yuan' },
	{ id: 'COP', name: 'Columbian Peso' },
	{ id: 'DKK', name: 'Danish Krone' },
	{ id: 'EGP', name: 'Egyptian Pound' },
	{ id: 'EUR', name: 'Euro' },
	{ id: 'GBP', name: 'British Pound' },
	{ id: 'HKD', name: 'Hong Kong Dollar' },
	{ id: 'IDR', name: 'Indonesian Rupiah' },
	{ id: 'ILS', name: 'Israeli New Shekel' },
	{ id: 'INR', name: 'Indian Rupee' },
	{ id: 'JPY', name: 'Japanese Yen' },
	{ id: 'KRW', name: 'South Korean Won' },
	{ id: 'LBP', name: 'Lebanese Pound' },
	{ id: 'MAD', name: 'Moroccan Dirham' },
	{ id: 'MXN', name: 'Mexican Peso' },
	{ id: 'MYR', name: 'Malaysian Ringgit' },
	{ id: 'NOK', name: 'Norwegian Krone' },
	{ id: 'NZD', name: 'New Zealand Dollar' },
	{ id: 'PHP', name: 'Philippine Peso' },
	{ id: 'PLN', name: 'Polish Zloty' },
	{ id: 'QAR', name: 'Qatari Rial' },
	{ id: 'RUB', name: 'Russian Ruble' },
	{ id: 'SAR', name: 'Saudi Riyal' },
	{ id: 'SEK', name: 'Swedish Krona' },
	{ id: 'SGD', name: 'Singapore Dollar' },
	{ id: 'THB', name: 'Thai Baht' },
	{ id: 'TRY', name: 'Turkish Lira' },
	{ id: 'TWD', name: 'New Taiwan Dollar' },
	{ id: 'USD', name: 'United States Dollar' },
	{ id: 'VND', name: 'Vietnamese Dong' },
	{ id: 'ZAR', name: 'South African Rand' },
]
